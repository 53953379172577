require('./bootstrap');
window.$ = window.jQuery = require('jquery');

$(function() {
  
  // Start "Navigation: Highlight on scroll"	
    // const sections = document.querySelectorAll("section");
    // const navLi = document.querySelectorAll("nav ul.main-nav li");

    // window.onscroll = () => {
    //   var current = "";
    
    //   sections.forEach((section) => {
    //     const sectionTop = section.offsetTop;
    //     if (pageYOffset >= sectionTop - 180) {
    //             current = section.getAttribute("id");                
    //             $(document).attr("title", "Café Wien Sylt - " + current);
    //         }
    //   });
    
    //   navLi.forEach((li) => {
    //     li.classList.remove("active");
    //     if (li.classList.contains(current)) {
    //             li.classList.add("active");
    //             console.log (current);
    //         }
    //   });

    // };
    // End "Navigation: Highlight on scroll!"

  //   $('ul li a').click(function(){
  //     $('li a').removeClass("active");
  //     $(this).addClass("active");
  // });   


  // Start "Navigation: Fuctions on click"
  $('nav a, linkscroll').click(function() {
      $('nav a').removeClass('active');
      $(this).addClass('active');
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'')
      && location.hostname == this.hostname) {
        var $target = $(this.hash);
        $target = $target.length && $target
        || $('[name=' + this.hash.slice(1) +']');
        if ($target.length) {
          var targetOffset = $target.offset().top;
          $('html,body')
          .animate( {scrollTop: targetOffset - 180}, 200);
          return false;
        }
      }
    });

    // End "Navigation: Fuctions on click"

      // Start "Script Timeline"
      var inputs = $('.input');
      var paras = $('.description-flex-container').find('p');
      inputs.click(function(){
        var t = $(this),
            ind = t.index(),
            matchedPara = paras.eq(ind);
        
        t.add(matchedPara).addClass('active');
        inputs.not(t).add(paras.not(matchedPara)).removeClass('active');
      });
       // End "Script Timeline"      

});




